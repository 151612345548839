import React from "react";
import { Spinner } from "react-bootstrap";
import Flex from "../Flex";

const Loading = () => {
  return (
    <Flex align="center" justify="center">
      <Spinner animation="grow" />
    </Flex>
  );
};

export default Loading;
