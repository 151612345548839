import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import './style.scss';
import "./style.css";
import { CalendarIcon } from "../icons";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const BaseDatePicker = ({ className, ...rest }: ReactDatePickerProps) => {
  return (
    <BoxCalendar className={className}>
      <DatePicker
        customInput={<Form.Control placeholder="Select time" />}
        showPopperArrow={false}
        {...rest}
      />
      <Icon>
        <CalendarIcon />
      </Icon>
    </BoxCalendar>
  );
};

BaseDatePicker.defaultProps = {
  placeholderText: "YYYY-MM-DD",
  dateFormat: "yyyy-MM-dd",
};

const BoxCalendar = styled.div`
  position: relative;
  width: fit-content;
`;

const Icon = styled.div`
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2px;
  right: 8px;
  position: absolute;
  pointer-events: none;
`;

export default BaseDatePicker;
