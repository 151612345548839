import axios from "../utils/axios";

const get = (link) => {
  return axios
    .get(`/fanpages`, {
      params: {
        link,
      },
    })
    .then((res) => res.data);
};

const getById = (id) => {
  return axios.get(`/fanpages/${id}`).then((res) => res.data);
};

const getPosts = (id, link) => {
  return axios
    .get(`/fanpages/${id}/posts`, {
      params: { link },
    })
    .then((res) => res.data);
};

const FanpageAPI = {
  get,
  getById,
  getPosts,
};
export default FanpageAPI;
