export const adStatus = {
  1: "ACTIVE",
  2: "DISABLED",
  3: "UNSETTLED",
  7: "PENDING_RISK_REVIEW",
  8: "PENDING_SETTLEMENT",
  9: "IN_GRACE_PERIOD",
  100: "PENDING_CLOSURE",
  101: "CLOSED",
  201: "ANY_ACTIVE",
  202: "ANY_CLOSED",
};

export const maxW_576 = "@media screen and (max-width: 576px)";
export const maxW_768 = "@media screen and (max-width: 768px)";
export const maxW_992 = "@media screen and (max-width: 992px)";
export const maxW_1200 = "@media screen and (max-width: 1200px)";

export const minW_768 = "@media screen and (min-width: 769px)";
export const minW_1400 = "@media screen and (min-width: 1400px)";
