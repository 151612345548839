import React, { useState, useEffect } from "react";
import { Badge, Button, Image, Spinner, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AdsAPI from "../../apis/ads.api";
import BusinessAPI from "../../apis/businesses.apis";
import { Loading } from "../../components";
import Flex from "../../components/Flex";
import { adStatus } from "../../constants/ads.constant";
import { useAppContext } from "../../context/appContext";
import ListAds from "../Ads/ListAds";

const AdsAccount = () => {
  const {
    businesses: { bmSelected },
    getBmInfo,
  } = useAppContext();
  const [data, setData] = useState([]);
  const [paging, setPaging] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getAds(id);
    getBmInfo(id);
  }, [id]);

  const getAds = async (bmId) => {
    setLoading(true);
    try {
      const { data, paging } = await AdsAPI.getAds(bmId);
      setData(data);
      setPaging(paging);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <Loading />;

  return (
    <ListAds
      data={data}
      bmSelected={bmSelected}
      loading={loading}
      paging={paging}
    />
  );
};

export default AdsAccount;
