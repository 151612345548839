import React from "react";
import PolicyWrapper from "../assets/wrappers/Policy";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../components/icons/ArrowLeft";

const Disclamer = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/");
  };
  return (
    <PolicyWrapper>
      <div className='back'>
        <i>
          <ArrowLeft
            onClick={goBack}
            width={48}
            height={40}
            viewBox='0 0 24 20'
          />
        </i>
      </div>
      <div className='header'>
        <h1>Disclamer</h1>
      </div>
      <div className='content'>
        <div className='txt'>
          <p>
            This website is provided by adplus.live. We provide Facebook ad
            account management services through our website adplus.live. By
            using our website, you agree to the following terms and conditions.
          </p>
          <ol>
            <li>
              The content of the website is for reference and information
              purposes only. We do not guarantee the accuracy, reliability, or
              completeness of any information displayed on our website.
            </li>
            <li>
              We are not liable for any damages arising from the use of our
              website or any actions taken based on the information provided on
              our website.
            </li>
            <li>
              We are not responsible for any technical issues, errors, or
              interruptions that may occur while using our website or any
              damages resulting from such incidents.
            </li>

            <li>
              We are not responsible for any illegal content or behavior by any
              user on our website.
            </li>
            <li>
              We are not responsible for any content or products of third-party
              websites linked or referred to on our website.
            </li>
            <li>
              We have no legal responsibility for any legal issues related to
              the use of our website.
            </li>
            <li>
              We are not responsible for any political or social advertisements
              displayed on our website. All such advertisements are the
              responsibility of the advertiser and not us. We do not control or
              endorse the content of these advertisements and do not make any
              representations as to their accuracy or reliability. The display
              of advertisements on our website does not represent our opinions
              or views on any topic.
            </li>
            <li>
              We reserve the right to refuse or remove any advertisements that
              we deem inappropriate under our rules and policies. We may also
              refuse or terminate service to any users who violate our terms and
              conditions.
            </li>
            <li>
              We are not responsible for any damages or losses arising from the
              refusal or removal of any advertisements, or from the refusal or
              termination of service to any users.
            </li>
            <li>
              We may change these terms and conditions at any time and we will
              notify users of any changes made. Continued use of our website
              after these changes are posted constitutes your agreement to these
              changes.
            </li>
            <li>
              The use of our website and the services provided on it must comply
              with Facebook's rules and policies. We are not responsible for any
              actions by users on the site that violate these rules and policies
              of Facebook.
            </li>
          </ol>

          <p>
            We encourage you to carefully read and understand the terms and
            conditions before using our website. If you do not agree with any of
            the terms, you should not use our website.
          </p>

          <p>
            We reserve the right to change any part of these terms and
            conditions at any time without prior notice. By continuing to use
            our website after any changes have been made, you agree to comply
            with the new terms and conditions.
          </p>

          <p>
            These terms and conditions are governed by the laws of the United
            Kingdom, and any disputes arising from the use of our website will
            be resolved according to the laws of the United Kingdom.
          </p>
          <p>
            If you have any questions or requests related to these terms and
            conditions, please contact us at contact@adsrunning.online or
            through the contact page on our website.
          </p>
        </div>
      </div>
    </PolicyWrapper>
  );
};

export default Disclamer;
