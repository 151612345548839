import { IoBarChartSharp } from "react-icons/io5";
import { FaAdversal, FaBusinessTime } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { RiPagesFill } from "react-icons/ri";

const links = [
  // { id: 1, text: "stats", path: "stats", icon: <IoBarChartSharp /> },
  {
    id: 2,
    text: "Businesses",
    path: "businesses",
    icon: <FaBusinessTime />,
  },
  {
    id: 3,
    text: "Ad Account",
    path: "ad-accounts",
    icon: <FaAdversal />,
  },
  { id: 5, text: "fanpages", path: "fanpages", icon: <RiPagesFill /> },
  { id: 5, text: "profile", path: "profile", icon: <ImProfile /> },
];

export default links;
