import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AdsAPI from "../../apis/ads.api";
import { Loading } from "../../components";
import { useAppContext } from "../../context/appContext";
import ListAds from "../Ads/ListAds";

const AdAccount = () => {
  const {
    businesses: { bmSelected },
  } = useAppContext();
  const [data, setData] = useState([]);
  const [paging, setPaging] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAds();
  }, []);

  const getAds = async (link) => {
    setLoading(true);
    try {
      const { adaccounts } = await AdsAPI.getAdAccount(link);
      setData(adaccounts?.data);
      setPaging(adaccounts?.paging);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onPaging = (link) => {
    getAds(link);
  };

  if (loading) return <Loading />;

  return (
    <>
      <h3>Ad Accounts</h3>
      <ListAds
        data={data}
        bmSelected={bmSelected}
        loading={loading}
        paging={paging}
        onPaging={onPaging}
      />
    </>
  );
};

export default AdAccount;
