import styled from "styled-components";

const Wrapper = styled.section`
  display: grid;
  align-items: center;
  .logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 1.38rem;
  }
  .form {
    max-width: 600px;
    border-top: 5px solid var(--primary-500);
  }

  h3 {
    text-align: center;
  }
  .txt_btn {
    margin: 0;
  }
  p {
    margin: 0;
    margin-top: 1rem;
  }
  .btn {
    margin-top: 1rem;
  }
  .member-btn {
    background: transparent;
    border: transparent;
    color: var(--primary-500);
    cursor: pointer;
    letter-spacing: var(--letterSpacing);
  }

  .btn-login {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 11px 32px;
    background: #4267b2;
    color: #fff;
    font-weight: 500;
    text-transform: unset;
    box-shadow: none;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;
    border: 1px solid transparent;
    margin-top: 30px;
  }
  .btn-policy {
    background: transparent;
    color: var(--primary-500);
    border: solid 1px var(--primary-500);
    margin-top: 8px;
    cursor: pointer;
  }
  .btn-disclamer {
    background: #be0606;
    color: white;
    border: none;
  }
`;
export default Wrapper;
