import * as React from "react"

export default (props) => {
    return (
        <svg width={12} height={10} viewBox="0 0 12 10" fill="none" {...props}>
            <path
                d="M11.25 4.25V5H2.033l3.48 3.486-.527.528L.598 4.625 4.986.236l.528.528-3.48 3.486h9.216z"
                fill="currentColor"
            />
        </svg>
    )
}