import React, { useState } from "react";
import UsersWrapper from "../../assets/wrappers/UsersWrapper";
import { Badge, ListGroup, Button, Table, ButtonGroup } from "react-bootstrap";
import Flex from "../../components/Flex";
import { Loading } from "../../components";
import ModalPage from "../Business/ModalPage";
import { Link } from "react-router-dom";

const ListPages = ({ loading, data = [], paging, onPaging }) => {
  const [pageSelected, setPageSelected] = useState(false);

  const onSelectPage = (page) => {
    setPageSelected(page);
  };
  const onCloseModal = () => {
    setPageSelected(null);
  };

  if (loading) return <Loading />;

  if (!data?.length) return <p>List empty</p>;
  return (
    <UsersWrapper>
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Name</th>
              <th>Category</th>
              <th>Likes</th>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {data?.map((page, index) => (
              <tr key={page.id}>
                <td>{index + 1}</td>
                <td>{page.id}</td>
                <td>{page.name}</td>
                <td>{page.category}</td>
                <td>{page.fan_count}</td>
                <td>
                  <Link to={`/panel/fanpages/${page.id}`}>
                    <Button size="sm" variant="secondary" className="me-2">
                      Posts
                    </Button>
                  </Link>
                  <Button size="sm" as="a" href={page.link} target="_blank">
                    Manager
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Flex justify="flex-end">
          <ButtonGroup className="mt-2">
            {paging?.previous && (
              <Button
                onClick={() => onPaging(paging.previous)}
                className="me-2"
              >
                Prev
              </Button>
            )}
            {paging?.next && (
              <Button onClick={() => onPaging(paging.next)}>Next</Button>
            )}
          </ButtonGroup>
        </Flex>
      </div>
      {pageSelected && (
        <ModalPage data={pageSelected} handleClose={onCloseModal} />
      )}
    </UsersWrapper>
  );
};

export default ListPages;
