import axios from "../utils/axios";

const getBusinesses = () => {
  return axios.get("/bm").then((res) => res.data);
};

const getBMSelected = (bmID) => {
  return axios.get(`/bm/${bmID}`).then((res) => res.data);
};

const changeRoles = ({ bmID, role }) => {
  return axios.post(`/bm/${bmID}`, `role=${role}`).then((res) => res.data);
};

const BusinessAPI = {
  getBusinesses,
  getBMSelected,
  changeRoles,
};
export default BusinessAPI;
