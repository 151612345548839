import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AdsAPI from "../../apis/ads.api";
import CampaignAPI from "../../apis/campaign.api";
import { Loading } from "../../components";
import Flex from "../../components/Flex";
import ListCampaigns from "./List";
import ModalInsight from "./ModalInsight";

const Campaign = () => {
  const [data, setData] = useState([]);
  const [adSelected, setAdSelected] = useState();
  const [paging, setPaging] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campaignSelected, setCampaignSelected] = useState();
  const { id } = useParams();

  useEffect(() => {
    loadAdSelected();
    load();
  }, [id]);

  const loadAdSelected = async () => {
    try {
      const data = await AdsAPI.getById(id);
      setAdSelected(data);
    } catch (error) {}
  };

  const load = async (link) => {
    try {
      setLoading(true);
      const { data, paging } = await CampaignAPI.getCampaigns(id, link);
      setData(data);
      setPaging(paging);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onPaging = (link) => {
    load(link);
  };
  const onInsight = (campaign) => {
    setCampaignSelected(campaign);
  };

  const handleCloseModal = () => {
    setCampaignSelected(null);
  };
  if (loading) return <Loading />;

  return (
    <div>
      <h3>List campaigns</h3>

      <Flex align="center" className="my-4">
        <div className="ms-2">
          <p className="mb-0">
            Name: <strong className="fw-500">{adSelected?.name}</strong>
          </p>
          <p>
            ID: <Badge bg="secondary">{adSelected?.id}</Badge>
          </p>
        </div>
      </Flex>
      <ListCampaigns
        data={data}
        loading={loading}
        paging={paging}
        onPaging={onPaging}
        onInsight={onInsight}
      />
      {campaignSelected && (
        <ModalInsight
          handleClose={handleCloseModal}
          campaignSelected={campaignSelected}
        />
      )}
    </div>
  );
};

export default Campaign;
