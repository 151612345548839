import React from 'react'
const CalendarIcon = ({ fill, ...rest }) => {
    return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path d="M2 18C1.4706 18 0.962804 17.7901 0.587904 17.4163C0.213003 17.0426 0.00158819 16.5354 0 16.006V3.994C0.00158819 3.46461 0.213003 2.95744 0.587904 2.58366C0.962804 2.20989 1.4706 2 2 2H3V1C3 0.734784 3.10536 0.48043 3.29289 0.292893C3.48043 0.105357 3.73478 0 4 0C4.26522 0 4.51957 0.105357 4.70711 0.292893C4.89464 0.48043 5 0.734784 5 1V2H13V1C13 0.734784 13.1054 0.48043 13.2929 0.292893C13.4804 0.105357 13.7348 0 14 0C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V2H16C16.5294 2 17.0372 2.20989 17.4121 2.58366C17.787 2.95744 17.9984 3.46461 18 3.994V16.006C17.9984 16.5354 17.787 17.0426 17.4121 17.4163C17.0372 17.7901 16.5294 18 16 18H2ZM2.255 14.718C2.25499 14.982 2.35939 15.2353 2.54541 15.4226C2.73144 15.61 2.984 15.7162 3.248 15.718H14.758C15.0218 15.7159 15.2741 15.6096 15.4599 15.4223C15.6458 15.235 15.75 14.9818 15.75 14.718V6.571H2.25L2.255 14.718ZM12 14V12H14V14H12ZM8 14V12H10V14H8ZM4 14V12H6V14H4ZM12 10V8H14V10H12ZM8 10V8H10V10H8ZM4 10V8H6V10H4Z" fill={fill} />
    </svg>

}

CalendarIcon.defaultProps = {
    fill: '#42526E'
}
export default CalendarIcon;