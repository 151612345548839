import React from "react";
import PolicyWrapper from "../assets/wrappers/Policy";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../components/icons/ArrowLeft";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/");
  };
  return (
    <PolicyWrapper>
      <div className="back">
        <i>
          <ArrowLeft
            onClick={goBack}
            width={48}
            height={40}
            viewBox="0 0 24 20"
          />
        </i>
      </div>
      <div className="header">
        <h1>Privacy Policy</h1>
      </div>
      <div className="content">
        <div className="txt">
          <p>
            We recognize that your privacy is very important and take it
            seriously. This Privacy Policy describes enior policies and
            procedures on the collection, use and disclosure of your information
            when you use the enior Service. We will not use or share your
            information with anyone except as described in this Privacy Policy.
          </p>
          <p>
            This Privacy Policy does not apply to information we collect by
            other means (including offline) or from other sources. Capitalized
            terms that are not defined in this Banda Policy have the meaning
            given them in our Terms of Service.
          </p>
          <p>
            All Our The Information of Your's is collected by a form in our
            website. We are not allowed to share your personal information or
            image with other person or any website. Your All Information is
            secure and trusted.
          </p>
          <p>
            Application allow permission:{" "}
            <strong>
              "pages_show_list", "pages_read_engagement" , "ads_read" ,
              "ads_management" & "business_management"
            </strong>
          </p>
          <p>
            For a better experience while using our Service, We may require you
            to allow us to post on your behalf.
          </p>
        </div>
      </div>
    </PolicyWrapper>
  );
};

export default PrivacyPolicy;
