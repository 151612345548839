import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Image, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import FanpageAPI from "../../apis/fanpage.api";
import { Loading } from "../../components";
import Flex from "../../components/Flex";

const Post = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [paging, setPaging] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load();
  }, [id]);

  const load = async (link) => {
    try {
      setLoading(true);
      const { data, paging } = await FanpageAPI.getPosts(id, link);
      setData(data);
      setPaging(paging);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onPaging = (link) => {
    load(link);
  };

  if (loading) return <Loading />;
  return (
    <div>
      <h3>List posts</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Picture</th>
            <th>Content</th>
            <td>Timestamp</td>
          </tr>
        </thead>
        <tbody>
          {data?.map((page, index) => (
            <tr key={page.id}>
              <td>{index + 1}</td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <Image src={page.full_picture} className="image-avt" />
                </div>
              </td>
              <td>
                <p className="my-0 text-truncate-2">
                  {page?.message || page?.story}
                </p>
              </td>

              <td>{dayjs(page?.created_time).format("YYYY-mm-DD HH:mm:ss")}</td>
              <td>
                <a href={page?.permalink_url} target="_blank">
                  <Button size="sm" variant="secondary" className="me-2">
                    View post
                  </Button>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Flex justify="flex-end">
        <ButtonGroup className="mt-2">
          {paging?.previous && (
            <Button onClick={() => onPaging(paging.previous)} className="me-2">
              Prev
            </Button>
          )}
          {paging?.next && (
            <Button onClick={() => onPaging(paging.next)}>Next</Button>
          )}
        </ButtonGroup>
      </Flex>
    </div>
  );
};

export default Post;
