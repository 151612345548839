import styled from "styled-components";

const UsersWrapper = styled.section`
  .item {
    cursor: pointer;
    transition: ease all 0.4s !important;

    &:hover {
      background-color: var(--bs-gray-200);
      a {
        color: #000;
      }
    }
  }
`;
export default UsersWrapper;
