import styled from "styled-components";

const Wrapper = styled.div`
  .header {
    height: 300px;
    background-color: #343a40;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .txt {
    max-width: 700px;
    p {
      text-align: left;
    }
  }
  .back {
    color: #fff;
    position: absolute;
    z-index: 9999;
    top: 16px;
    left: 12px;
    i {
      font-size: 26px;
      cursor: pointer;
    }
  }
`;

export default Wrapper;
