
const Logo = () => {
  return (
    <h2
      style={{
        color: "#2CB1BC",
        fontWeight: 600,
      }}
    >
      AdPlus.Live
    </h2>
  );
};

export default Logo;
