import React from 'react'
const SuccessIcon = ({ fill, ...rest }) => {
    return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...rest}>
        <path d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C11.9982 4.40926 11.3654 2.8842 10.2406 1.75938C9.1158 0.634557 7.59074 0.0018286 6 0V0ZM9.34546 4L5.65273 8.15273C5.57412 8.24111 5.4646 8.29597 5.34674 8.30598C5.22888 8.316 5.11167 8.28041 5.01928 8.20655L2.71164 6.36036C2.61602 6.28388 2.55469 6.17255 2.54115 6.05086C2.52762 5.92916 2.56298 5.80708 2.63946 5.71145C2.71594 5.61583 2.82727 5.55451 2.94896 5.54097C3.07066 5.52743 3.19274 5.56279 3.28837 5.63927L5.25382 7.21164L8.65455 3.38545C8.73604 3.29383 8.85059 3.23834 8.973 3.23118C9.09542 3.22402 9.21566 3.26578 9.30728 3.34727C9.3989 3.42877 9.45439 3.54332 9.46155 3.66573C9.46871 3.78814 9.42695 3.90838 9.34546 4Z" fill={fill} />
    </svg>

}

SuccessIcon.defaultProps = {
    fill: '#48CE76'
}
export default SuccessIcon;