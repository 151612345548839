import React, { useEffect, useState } from "react";
import FanpageAPI from "../../apis/fanpage.api";
import ListPages from "./List";

const Fanpage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState();

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      setLoading(true);
      const { data, paging } = await FanpageAPI.get();
      setData(data);
      setPaging(paging);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h3>Fanpage</h3>
      <ListPages data={data} loading={loading} />
    </div>
  );
};

export default Fanpage;
