import axios from "../utils/axios";

const getCampaigns = (adAccount, link) => {
  return axios
    .get(`/campaign`, {
      params: {
        adAccount,
        link,
      },
    })
    .then((res) => res.data);
};

const insights = ({ id, time_range }) => {
  return axios
    .get(`/campaign/${id}/insights`, {
      params: {
        time_range,
      },
    })
    .then((res) => res.data);
};
const CampaignAPI = {
  getCampaigns,
  insights,
};
export default CampaignAPI;
