import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAppContext } from "./context/appContext";
import { Register, Landing, Error, ProtectedRoute } from "./pages";
import Business from "./pages/Business";
import { Profile, SharedLayout, Stats, AddJob } from "./pages/dashboard";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import User from "./pages/Business/User";
import Pages from "./pages/Fanpage/List";
import AdsAccount from "./pages/Business/AdsAccount";
import AdAccount from "./pages/Ads/AdAccount";
import Campaign from "./pages/Campaigns";
import Fanpage from "./pages/Fanpage";
import BmPage from "./pages/Business/BmPage";
import Post from "./pages/Fanpage/Post";
import Disclamer from "./pages/Disclaimer";

function AppInit({ children }) {
  const { initUser } = useAppContext();
  useEffect(() => {
    initUser();
  }, []);
  return children;
}

function App() {
  return (
    <BrowserRouter>
      <AppInit>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route
            path="/panel"
            element={
              <ProtectedRoute>
                <SharedLayout />
              </ProtectedRoute>
            }
          >
            {/* <Route path='stats' element={<Stats />} /> */}
            <Route path="businesses" element={<Business />} />
            <Route path="businesses/:id/users" element={<User />} />
            <Route path="businesses/:id/pages" element={<BmPage />} />
            <Route path="businesses/:id/ads" element={<AdsAccount />} />
            <Route path="ad-accounts" element={<AdAccount />} />
            <Route path="ad-accounts/:id/campaigns" element={<Campaign />} />
            <Route path="profile" element={<Profile />} />
            <Route path="fanpages" element={<Fanpage />} />
            <Route path="fanpages/:id" element={<Post />} />
          </Route>
          <Route path="/login" element={<Register />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclamer />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </AppInit>
    </BrowserRouter>
  );
}

export default App;
