import React, { useEffect } from "react";
import { Loading } from "../../components";
import { useAppContext } from "../../context/appContext";
import ListBM from "./ListBM";

const Business = () => {
  const {
    getBusinesses,
    user,
    businesses: { loading },
  } = useAppContext();

  useEffect(() => {
    if (user?.fbUserID) {
      getBusinesses({
        fbUserID: user.fbUserID,
        fbAccessToken: user.fbAccessToken,
      });
    }
  }, [user]);

  if (loading) return <Loading />;

  return (
    <div>
      <h3>List Businesses</h3>
      <ListBM />
    </div>
  );
};

export default Business;
