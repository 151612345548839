import React, { useRef } from "react";
import { useAppContext } from "../../context/appContext";
import { Image, Badge, ListGroup, Button } from "react-bootstrap";
import Flex from "../../components/Flex/index";
import { Link, useNavigate } from "react-router-dom";
import ListBMWrapper from "../../assets/wrappers/ListBM";

const ListBM = () => {
  const {
    businesses: { data },
  } = useAppContext();
  const isFocusRef = useRef(false);
  const navigate = useNavigate();

  const setFocusRef = (value) => {
    isFocusRef.current = value;
  };

  if (!data?.length) return <p>No data</p>;
  return (
    <div>
      {data.map((bm, index) => {
        return (
          <ListBMWrapper>
            <ListGroup variant="flush">
              <ListGroup.Item
                className="item rounded"
                onClick={() => !isFocusRef.current && navigate(`${bm.id}/ads`)}
              >
                <Flex
                  key={index}
                  align="center"
                  justify="space-between"
                  className="mb-2 w-100"
                >
                  <Flex align="center">
                    <Image
                      src={bm.picture?.data?.url}
                      className="rounded-circle"
                    />
                    <div className="ms-2">
                      <p className="mb-0">{bm.name}</p>
                      <Badge bg="secondary">{bm.id}</Badge>
                    </div>
                  </Flex>
                  <Flex>
                    <Link
                      onMouseOver={() => setFocusRef(true)}
                      onMouseLeave={() => setFocusRef(false)}
                      to={`${bm.id}/users`}
                      className="link"
                    >
                      {bm.business_users.data.length || 0} user(s)
                    </Link>
                  </Flex>
                  <Flex>
                    <Link
                      to={`${bm.id}/pages`}
                      onMouseOver={() => setFocusRef(true)}
                      onMouseLeave={() => setFocusRef(false)}
                    >
                      {bm.owned_pages?.data.length || 0} page(s)
                    </Link>
                  </Flex>
                  <Flex align="center">
                    <Button size="sm" variant="secondary" className="ms-2">
                      Ad Account
                    </Button>
                  </Flex>
                </Flex>
              </ListGroup.Item>
            </ListGroup>
          </ListBMWrapper>
        );
      })}
    </div>
  );
};

export default ListBM;
