import dayjs from "dayjs";
import React from "react";
import { Button, ButtonGroup, Table } from "react-bootstrap";
import { Loading } from "../../components";
import Flex from "../../components/Flex";

const ListCampaigns = ({ data, paging, onPaging, loading, onInsight }) => {
  if (loading) return <Loading />;

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>Name</th>
            <th>Objective</th>
            {/* <th>Daily budget</th> */}
            <th>Lifetime Budget</th>
            <th>Budget remaining</th>
            <th>Start time</th>
            <th>Status</th>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {data?.map((ads, index) => (
            <tr key={ads.id}>
              <td>{index + 1}</td>
              <td>{ads.id}</td>
              <td>{ads.name}</td>
              <td>{ads.objective || "-"}</td>
              {/* <td>{ads.daily_budget || "-"}</td> */}
              <td>{ads.lifetime_budget || "-"}</td>
              <td>{ads.budget_remaining || "-"}</td>
              <td>
                {ads.start_time
                  ? dayjs(ads.start_time).format("YYYY-MM-DD HH:mm")
                  : "-"}
              </td>
              <td>{ads.status}</td>
              <td>
                <Button size="sm" onClick={() => onInsight(ads)}>
                  Insights
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Flex justify="flex-end">
        <ButtonGroup className="mt-2">
          {paging?.previous && (
            <Button onClick={() => onPaging(paging.previous)} className="me-2">
              Prev
            </Button>
          )}
          {paging?.next && (
            <Button onClick={() => onPaging(paging.next)}>Next</Button>
          )}
        </ButtonGroup>
      </Flex>
    </div>
  );
};

export default ListCampaigns;
