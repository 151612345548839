import axios from "../utils/axios";

const getAds = (bmID) => {
  return axios.get(`/ads/bm-ads?bmID=${bmID}`).then((res) => res.data);
};

const getPersonalAds = () => {
  return axios.get(`/ads/personal-ads`).then((res) => res.data);
};
const getAdAccount = (link) => {
  return axios
    .get(`/ads/ad-account`, {
      params: { link },
    })
    .then((res) => res.data);
};

const getById = (id) => {
  return axios.get(`/ads/${id}`).then((res) => res.data);
};

const AdsAPI = {
  getPersonalAds,
  getAdAccount,
  getAds,
  getById,
};
export default AdsAPI;
