import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import dayjs from "dayjs";
import CampaignAPI from "../../apis/campaign.api";
import Flex from "../../components/Flex";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";

const RenderStatus = ({ status }) => {
  switch (status) {
    case "ACTIVE":
      return (
        <Flex align="center">
          <p className="text-primary mb-0 me-3 fw-500">{status}</p>
          <Button size="sm" variant="info">
            PAUSE
          </Button>
        </Flex>
      );
    case "PAUSED":
      return (
        <Flex align="center">
          <p className="text-info mb-0 me-3 fw-500">{status}</p>
          <Button size="sm">ACTIVE</Button>
        </Flex>
      );
    case "DELETED":
      return <p className="text-danger mb-0 me-3 fw-500">{status}</p>;
    case "ARCHIVED":
      return <p className="text-secondary mb-0 me-3 fw-500">{status}</p>;

    default:
      return status || "-";
  }
};

const ModalInsight = ({ handleClose, campaignSelected }) => {
  const [data, setData] = useState();
  const [paging, setPaging] = useState();
  const [date, setDate] = useState();

  useEffect(() => {
    load();
  }, [campaignSelected]);

  const load = async (date) => {
    try {
      const res = await CampaignAPI.insights({
        id: campaignSelected.id,
        time_range: {
          since: dayjs(date.fromDate).format("YYYY-MM-DD"),
          until: dayjs(date.toDate).format("YYYY-MM-DD"),
        },
      });
      setData(res.data[0]);
      setPaging(res.paging);
    } catch (error) {}
  };

  const onChangeDateRange = (date) => {
    if (date.fromDate && date.toDate) {
      load(date);
    }
  };

  return (
    <div>
      <Modal
        show={true}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{campaignSelected?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex justify="flex-end" className="mb-3 w-100">
            <DateRangePicker onChange={onChangeDateRange} value={date} />
          </Flex>
          <Table bordered hover>
            <tbody>
              <tr>
                <td style={{ width: 200 }}>ID:</td>
                <td>{campaignSelected.id}</td>
              </tr>
              <tr>
                <td>Name:</td>
                <td>{campaignSelected.name}</td>
              </tr>
              <tr>
                <td>Objective:</td>
                <td>{campaignSelected.objective}</td>
              </tr>
              <tr>
                <td>Status:</td>
                <td>
                  <Flex align="center">
                    <RenderStatus status={campaignSelected.status} />
                  </Flex>
                </td>
              </tr>
              <tr>
                <td>Lifetime Budget:</td>
                <td>{campaignSelected.lifetime_budget || "-"}</td>
              </tr>
              <tr>
                <td>Daily Budget:</td>
                <td>{campaignSelected.daily_budget || "-"}</td>
              </tr>
              <tr>
                <td>Impressions:</td>
                <td>{data?.impressions}</td>
              </tr>
              <tr>
                <td>Spend:</td>
                <td>{data?.spend}</td>
              </tr>
              <tr>
                <td>Start time:</td>
                <td>
                  {dayjs(campaignSelected.start_time).format("YYYY-MM-DD")}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalInsight;
