import main from "../assets/images/main.svg";
import Wrapper from "../assets/wrappers/LandingPage";
import { Logo } from "../components";
import Footer from "../components/Footer";
const Landing = () => {
  return (
    <Wrapper>
      <nav>
        <Logo />
      </nav>
      <div className='container page'>
        {/* info */}
        <div className='info'>
          <h1>
            Ads <span>Pro</span> Tools
          </h1>
          <p>Have you experienced advertising on Facebook Ads Tools?</p>
          <p>
            With AdPlus.Live, you can view information about advertising
            accounts also could see Ads Insights via Facebook Marketing API.
            Reach new customers and advertise successfully!
          </p>
          <p>Sign up and elevate your campaign today!</p>
          <a href='/login' className='btn btn-hero'>
            Getting started
          </a>
        </div>
        <img src={main} alt='job hunt' className='img main-img' />
      </div>
      <Footer />
    </Wrapper>
  );
};

export default Landing;
