import React, { useState, useEffect, useMemo, useRef } from "react";
import Flex from "../Flex";
import styled from "styled-components";
import { maxW_576, maxW_768 } from "../../constants/ads.constant";
import BaseDatePicker from "./BaseDatePicker";
import dayjs from "dayjs";

const DateRangePicker = ({ onChange, value, styleSelect }) => {
  const toDateRef = useRef();
  const [date, setDate] = useState(() =>
    value
      ? value
      : {
          fromDate: null,
          toDate: null,
        }
  );

  useEffect(() => {
    if (value) setDate(value);
  }, [value]);

  const onSelectDate = (key, value) => {
    let d = { ...date };
    d[key] = value;

    if (d.toDate && d.fromDate) {
      if (dayjs(d.toDate).isBefore(d.fromDate)) {
        d.toDate = d.fromDate;
      }
      //  else if (dayjs(d.toDate).diff(d.fromDate, "days") > 30) d.toDate = null;

      onChange({
        ...d,
        toDate: d.toDate,
      });
    }
    setDate(d);
    if (d.toDate && d.fromDate) onChange(d);
  };
  return (
    <Wrapper>
      <Field>
        <Label>From</Label>
        <BaseDatePicker
          popperPlacement="bottom-end"
          sx={{ width: 150, ...styleSelect }}
          selected={date.fromDate}
          maxDate={new Date()}
          onChange={(date) => onSelectDate("fromDate", date)}
        />
      </Field>
      <Field>
        <Label>to </Label>
        <BaseDatePicker
          popperPlacement="bottom-end"
          sx={{ width: 150, ...styleSelect }}
          selected={date.toDate}
          minDate={date.fromDate}
          customInputRef={toDateRef}
          maxDate={new Date()}
          onChange={(date) => onSelectDate("toDate", date)}
        />
      </Field>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  align-items: center;
  &:not(:last-child) {
    margin-right: 24px;
  }
  flex-wrap: wrap;
  ${maxW_576} {
    padding: 0;
    margin: 0;
    flex-direction: column;
  }
`;

const Label = styled.p`
  margin: 0;
  ${maxW_768} {
    min-width: 30px;
    text-align: center;
  }
  ${maxW_576} {
    min-width: 72px;
    text-align: left;
  }
`;

const Field = styled(Flex)`
  align-items: center;
  ${maxW_768} {
    margin-bottom: 8px;
  }
  ${maxW_576} {
    width: 100%;
  }
  input {
    margin: 0;
  }
  :first-of-type {
    ${Label} {
      margin-right: 6px;
    }
  }
  :not(:first-of-type) {
    ${Label} {
      margin: 0 12px;
      ${maxW_576} {
        margin-right: 0;
        margin-left: 6px;
      }
    }
  }
`;
export default DateRangePicker;
