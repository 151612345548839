import { useState, useEffect } from "react";
import { Logo, FormRow, Alert } from "../components";
import Wrapper from "../assets/wrappers/RegisterPage";
import { useAppContext } from "../context/appContext";
import { useNavigate } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import Footer from "../components/Footer";

const initialState = {
  name: "",
  email: "",
  password: "",
  isMember: true,
};

const Register = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const { user, isLoading, showAlert, displayAlert, loginSocial } =
    useAppContext();

  // useEffect(() => {
    

  //   return () => {};
  // }, []);

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/panel/businesses");
      }, 1000);
    }
  }, [user, navigate]);

  const responseFacebook = async (response) => {
    console.log(
      "🚀 ~ file: Register.js ~ line 35 ~ responseFacebook ~ response",
      response
    );

    if (response.id) {
      await loginSocial({
        currentUser: response,
        alertText: "Login Successful! Redirecting...",
      });
    }
  };
  const login = () => {
    let obj = {};

    window.FB.Event.subscribe("auth.statusChange", function (response) {
      console.log("🚀 ~ file: Register.js:53 ~ response:", response)
    });
    
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          console.log("Đã đăng nhập thành công!", response);
          obj = response.authResponse;
          window.FB.api(
            "/me",
            function (response) {
              obj = {
                ...response,
                ...obj,
              };

              if (obj.id) {
                loginSocial({
                  currentUser: obj,
                  alertText: "Login Successful! Redirecting...",
                });
              }
            },
            {
              fields: "email,id,name",
            }
          );
          // Xử lý khi đăng nhập thành công ở đây
        } else {
          console.log("Đăng nhập bị hủy bỏ hoặc không thành công.");
          console.log(response);
        }
      },
      {  scope:
        "public_profile,pages_show_list,pages_read_engagement,ads_read,ads_management,business_management",}
    );
  };

  return (
    <Wrapper className="full-page">
      <div className="form">
        <Logo />
        {showAlert && <Alert />}
        <p>
          With AdPlus.Live, you can view information about advertising accounts
          also could see Ads Insights via Facebook Marketing API.
        </p>
        {/* name input */}
        <button className="btn-login" onClick={login}>
          Login with Facebook
        </button>
        <button
          className="btn-login btn-policy"
          onClick={() => navigate("/privacy-policy")}
        >
          <p className="txt_btn">
            <i className="fa-solid fa-shield"></i>
            <span> Privacy Policy</span>
          </p>
        </button>
        <button
          className="btn-login btn-policy btn-disclamer"
          onClick={() => navigate("/disclaimer")}
        >
          <p className="txt_btn">
            <i className="fa-solid fa-shield"></i>
            <span>Disclaimer</span>
          </p>
        </button>
      </div>
      <Footer />
    </Wrapper>
  );
};
export default Register;
