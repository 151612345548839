import Axios from "axios";
import { GRAPH_URL } from "../appConfig";

const graphAxios = Axios.create({
  timeout: 30000,
  baseURL: GRAPH_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  params: {},
});

graphAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async function (err) {
    const { error } = err?.response?.data;
    // if token expired
    if (error && error.code === 190) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(err);
  }
);
export const setGraphToken = (token) => {
  graphAxios.defaults.params["access_token"] = token;
};

export default graphAxios;
