import React, { useEffect } from "react";
import UsersWrapper from "../../assets/wrappers/UsersWrapper";
import { useAppContext } from "../../context/appContext";
import { Image, Badge, ListGroup, Button } from "react-bootstrap";
import Flex from "../../components/Flex";
import { Link, useParams } from "react-router-dom";
import { Loading } from "../../components";

const User = () => {
  const {
    businesses: { bmSelected, loading },
    getBmInfo,
    user,
  } = useAppContext();

  const params = useParams();
  useEffect(() => {
    getBmInfo(params.id);
  }, [params.id, user]);

  if (loading) return <Loading />;

  if (!bmSelected?.business_users?.data?.length) return <p>No user</p>;
  return (
    <UsersWrapper>
      <h3 className="text-normal">List users</h3>

      <Flex align="center" className="my-4">
        <Image src={bmSelected.picture?.data?.url} className="rounded-circle" />
        <div className="ms-2">
          <p className="mb-0">{bmSelected.name}</p>
          ID: <Badge bg="secondary">{bmSelected.id}</Badge>
        </div>
      </Flex>
      <div>
        {bmSelected?.business_users?.data?.map((user, index) => {
          return (
            <ListGroup variant="flush">
              <ListGroup.Item className="item rounded">
                <Flex
                  key={index}
                  align="center"
                  justify="space-between"
                  className="mb-2 w-100"
                >
                  <Flex align="center">
                    <div className="ms-2">
                      <p className="mb-0">{user.name}</p>
                    </div>
                  </Flex>
                  <Badge bg={user.role === "ADMIN" ? "danger" : "info"}>
                    {user.role}
                  </Badge>
                  <Flex align="center">
                    <Button size="sm">Remove</Button>
                    <Button size="sm" variant="secondary" className="ms-2">
                      Change permission
                    </Button>
                  </Flex>
                </Flex>
              </ListGroup.Item>
            </ListGroup>
          );
        })}
      </div>
    </UsersWrapper>
  );
};

export default User;
