import dayjs from "dayjs";
import React from "react";
import { Button, Modal, Table } from "react-bootstrap";

const ModalPage = ({ data = {}, handleClose }) => {
  console.log("🚀 ~ file: ModalPage.js ~ line 6 ~ ModalPage ~ data", data);
  return (
    <Modal
      show={true}
      size="lg"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{data?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered hover>
          <tbody>
            <tr>
              <td style={{ width: 200 }}>ID:</td>
              <td>{data.id}</td>
            </tr>
            <tr>
              <td>Name:</td>
              <td>{data.name}</td>
            </tr>
            <tr>
              <td>Objective:</td>
              <td>{data.objective}</td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>{data.status}</td>
            </tr>
            <tr>
              <td>Lifetime Budget:</td>
              <td>{data.lifetime_budget || "-"}</td>
            </tr>
            <tr>
              <td>Daily Budget:</td>
              <td>{data.daily_budget || "-"}</td>
            </tr>
            <tr>
              <td>Impressions:</td>
              <td>{data.impressions}</td>
            </tr>
            <tr>
              <td>Spend:</td>
              <td>{data.spend_cap}</td>
            </tr>
            <tr>
              <td>Start time:</td>
              <td>{dayjs(data.start_time).format("YYYY-MM-DD")}</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPage;
