import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <h3>AdPlus.Live</h3>
      <div className="footer-link">
        <a href="/privacy-policy">Privacy policy</a>
        <p className="divider">|</p>
        <a href="/disclaimer">Disclaimer</a>
      </div>

      <p style={{ color: "white", marginTop: 4 }}>
        Contact: Via Leonardo, 5 00053 Civitavecchia RM, Italy
      </p>
    </div>
  );
};

export default Footer;
