import React from 'react'

const DangerIcon = ({ fill, ...rest }) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"  {...rest}>
            <circle cx="6" cy="6" r="6" fill="red" />
            <path d="M6.4248 6L9 8.5752L8.5752 9L6 6.4248L3.4248 9L3 8.5752L5.5752 6L3 3.4248L3.4248 3L6 5.5752L8.5752 3L9 3.4248L6.4248 6Z" fill="white" fill={fill} />
        </svg>

    )
}

DangerIcon.defaultProps = {
    fill: 'white'
}
export default DangerIcon;