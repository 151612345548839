import React from "react";
import { Button, ButtonGroup, Table } from "react-bootstrap";
import { Loading } from "../../components";
import { adStatus } from "../../constants/ads.constant";
import NumberFormat from "react-number-format";
import Flex from "../../components/Flex";
import { useNavigate } from "react-router-dom";

const ListAds = ({ data, loading, paging, onPaging }) => {
  const navigate = useNavigate();
  if (loading) return <Loading />;
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>Name</th>
            <th>Balance</th>
            <th>Amount spent</th>
            <th>Currency</th>
            <th>Timezone</th>
            <td>Status</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {data?.map((ads, index) => (
            <tr key={ads.id}>
              <td>{index + 1}</td>
              <td>{ads.id}</td>
              <td>{ads.name}</td>
              <td>
                <NumberFormat
                  value={ads.balance}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
              <td>
                <NumberFormat
                  value={ads.amount_spent}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
              <td>{ads.currency}</td>
              <td>
                {ads.timezone_name} (
                {ads.timezone_offset_hours_utc > 0
                  ? `+${ads.timezone_offset_hours_utc}`
                  : ads.timezone_offset_hours_utc}
                )
              </td>
              <td>{adStatus[ads.account_status]}</td>
              <td>
                <Button
                  size="sm"
                  onClick={() =>
                    navigate(`/panel/ad-accounts/${ads.id}/campaigns`)
                  }
                >
                  Campaigns
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Flex justify="flex-end">
        <ButtonGroup className="mt-2">
          {paging?.previous && (
            <Button onClick={() => onPaging(paging.previous)} className="me-2">
              Prev
            </Button>
          )}
          {paging?.next && (
            <Button onClick={() => onPaging(paging.next)}>Next</Button>
          )}
        </ButtonGroup>
      </Flex>
    </>
  );
};

export default ListAds;
